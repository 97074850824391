export const educationData = [

    {
        id: 2,
        institution: 'The University of Winnipeg',
        course: 'Project Management Diploma',
        startYear: '2020',
        endYear: '2021'
    },
    {
        id: 1,
        institution: 'Bangalore University, India',
        course: 'Bachelor’s Degree in Computer Applications',
        startYear: '2014',
        endYear: '2018'
    },
]