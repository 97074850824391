export const achievementData = {
    bio: "Positive attitude, fast learner, ability to multitask, self-motivated, and able to work well alone or within a team environment.",
    achievements: [
        {
            id: 1,
            title: 'Agile Project Management ',
            details: 'University of Winnipeg (PACE) | Winnipeg',
            date: 'Feb, 2021',
            field: '',
            image: 'https://images.unsplash.com/photo-1489389944381-3471b5b30f04?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80'
        },
        {
            id: 2,
            title: 'Certified Ethical Hacking (CEH)',
            details: 'IT Training Nepal | Kathmandu, Nepal',
            date: 'Feb, 2020',
            field: '',
            image: 'https://images.unsplash.com/photo-1489389944381-3471b5b30f04?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80'
        },
        {
            id: 3,
            title: 'Building Dynamic Websites, SQL Server, Dreamweaver',
            details: 'APTECH Computer Education| Kathmandu, Nepal',
            date: 'May, 2019',
            field: '',
            image: 'https://images.unsplash.com/photo-1489389944381-3471b5b30f04?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80'
        },
        {
            id: 4,
            title: 'Information Systems Management ',
            details: 'APTECH Computer Education | Kathmandu, Nepal',
            date: 'May, 2019',
            field: '',
            image: 'https://images.unsplash.com/photo-1489389944381-3471b5b30f04?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80'
        },
        {
            id: 5,
            title: 'Network Engineer',
            details: 'APTECH Computer Education | Kathmandu, Nepal',
            date: 'March, 2019',
            field: '',
            image: 'https://images.unsplash.com/photo-1489389944381-3471b5b30f04?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80'
        },

        {
            id: 7,
            title: 'Relational Database Management Systems',
            details: 'NIIT | Bangalore, India',
            date: 'March, 2018',
            field: '',
            image: 'https://images.unsplash.com/photo-1489389944381-3471b5b30f04?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80'
        },
        {
            id: 8,
            title: 'Object-Oriented Programming Using C#',
            details: 'NIIT | Bangalore, India',
            date: 'October, 2017',
            field: '',
            image: 'https://images.unsplash.com/photo-1489389944381-3471b5b30f04?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80'
        }

    ]
}


// Do not remove any fields.
// Leave it blank instead as shown below.

/*

export const achievementData = {
    bio : "",
    achievements : []
}

*/