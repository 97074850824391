import resume from "../assets/pdf/resume.pdf";
import vidur_Twari from "../assets/img/vidur_Twari.jpg";
export const headerData = {
  name: "Vidur Tiwari",
  title: "Web Developer",
  desciption:
    "Hi, I’m Vidur, a passionate Web Developer, experienced in making your website ideas come true. Let’s collaborate to craft digital experiences that captivate and inspire. ",
  image: vidur_Twari,
  resumePdf: resume,
};
