export const aboutData = {
  title: "Who I am",
  description1:
    "I'm a Performance-driven Web Developer and Designer based in Calgary, Alberta, Canada.",
  description2:
    "Graduated with a Bachelor's degree in Computer Applications, experienced in working with diverse Web projects, I am a positive, enthusiastic problem solver able to develop amazing websites.",
  description3:
    "Throughout my career, I have honed my ability to blend creativity with technical proficiency, ensuring that each website I create is not just visually appealing but also optimized for performance and usability. I believe in the power of collaboration and am committed to delivering exceptional results that exceed expectations.",
  description4:
    "Let's join forces to transform your concepts into impactful digital experiences. Together, we can build websites that leave a lasting impression and elevate your online presence. I look forward to the opportunity to work with you and bring your vision to life.",
  image: 1,
};
